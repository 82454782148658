import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f4f9fa;
  height: 100vh;
  padding: 0;
`;

const StyledHeaderContainer = styled.div`
  font-size: 50px;
  color: #006384;
`;

const StyledDescriptionContainer = styled.div`
  font-size: 28px;
  margin-top: 20px;
`;

const StyledTextContainer = styled.div`
  font-size: 28px;
  margin-top: 10px;
`;

const TechworkPage = () => {
    return (
        <StyledContainer>
            <StyledHeaderContainer>
                На сайте ведутся технические работы
            </StyledHeaderContainer>
            <StyledDescriptionContainer>
                Приносим свои извинения за доставленные неудобства.
            </StyledDescriptionContainer>
            <StyledTextContainer>
                Пожалуйста, обновите страницу или зайдите на сайт позднее.
            </StyledTextContainer>
            <StyledTextContainer>
                Вы также можете воспользоваться горячей линией для проверки
                уникального кода товара тел.: +7 800 700-73-28
            </StyledTextContainer>
      </StyledContainer>
    )
}

export default TechworkPage;